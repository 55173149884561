<template>
  <div class="main-page d-flex flex-column justify-center gray-p--bg">
    <DHat
      :crumbs-items="crumbsItems"
      :h1="h1"
      :h2="subtitle"
      :show-benefits="false"
    />

    <LazyMainPageCollect
      v-if="counts"
      :counts="counts"
    />

    <LazyMainPageProviders
      v-if="counts"
      :counts="counts"
      :providers="providers"
      :all-providers="allProviders"
    />

    <div class="container">
      <LazyQuizBlock />
    </div>

    <LazyMainPageAbout />

    <LazyBlockCities
      :cities="cities"
      :regions="regions"
    />

    <LazyMainPageHelp />

    <LazyMainPageLatestConnected
      v-if="counts"
      :counts="counts"
      :latest-connected="latestConnected"
    />
    <LazyThatsWhy :title="h2?.why" />

    <div class="container">
      <LazyBlockHelp />
    </div>

    <LazySearchAddressPageSearchAddressFAQ
      :title="textAggReplace(h2?.FAQ || '', getAddressText)"
      custom-class="headline-2"
      :faq-block="faqBlock"
    />

    <LazyMainPageBlog
      :articles="articles"
      :tags="tags"
      :title="h2?.blog"
    />
    <LazyMainPageConnect
      v-if="counts"
      :title="h2?.blue3steps"
      :subtitle="`На сайте ДомИнтернет легко найти и подключить тариф от надёжного провайдера по цене от ${counts?.tariffPriceMin} до ${counts?.tariffPriceMax} руб/мес.`"
    />
  </div>
</template>

<script setup lang="ts">
import { useCities } from '~/store/cites/cities.store'
import { useMainStore } from '~/store/main/main.store'
import removeUtm from '~/composible/removeUtm'
import { textAggReplace } from '~/helpers'
import getTextAsync from '~/composible/getPageText'
import { providersComposible } from '~/composible/getProviders'

const mainStore = useMainStore()
const cityStore = useCities()
const ctx = useNuxtApp()
const $route = useRoute()
const currentCity = computed(() => cityStore.getCity)
const getCommonHeaders = computed(() => mainStore.getCommonHeaders)

const crumbsItems = computed(() => {
  return []
})
const h1 = computed(() => textAsync.value?.h1?.replace(/-/g, '\u2011') || '')
const subtitle = computed(
  () =>
    textAsync.value?.h2?.replace(/\\/g, '\n').replace(/_/g, '&nbsp;'),
)
const h2 = computed(
  () =>
    textAsync.value?.extra?.h2_titles,
)

const getAddressText = computed(() => cityStore.getAddressText)

const faqBlock = computed(() => textAsync.value?.blocks?.faqs)

const client = ctx._apolloClients.default

const fetchBlog = async () => {
  let res
  try {
    res = await $fetch(`https://${mainStore.domain}/api/blog/posts/`, {
      method: 'GET',
      headers: {
        ...getCommonHeaders.value,
      },
      params: {
        site_id: 0,
        device: ctx.$device.isTablet ? 'TABLET' : 'MOBILE',
        offset: 0,
        limit: 8,
        for_page_url: '/',
      },
    })
  }
  catch { /* empty */ }
  return Object.assign({}, res)
}
const fetchTags = async () => {
  return await $fetch(`https://${mainStore.domain}/api/blog/tags/`, {
    method: 'GET',
    headers: {
      ...getCommonHeaders.value,
    },
    params: {},
  })
}

const fetchProviders = async () => {
  const res = await client.query({
    query: gql`
      query getAllProviders($getProvidersInput2: ProvidersInput) {
        core {
          getProviders(input: $getProvidersInput2) {
            data {
              id
              slug
              name
              logoT {
                url(options: { width: 200, halign: "left" })
              unoptimized
              }
            }
          }
        }
      }
    `,
    variables: {
      getProvidersInput2: {
        withTarifflessAddresses: false,
        pageSize: 12,
      },
    },
    context: {
      headers: getCommonHeaders.value,
    },
    fetchPolicy: 'no-cache',
  })

  return res.data.core.getProviders.data
}

const { fetchProvidersList } = providersComposible()
const fetchCounts = async () => {
  const res = await client.query({
    query: gql`
      query getCoreCounts {
        core {
          globalAgg {
            providersCount
            tariffsCount
            housesCount
            streetsCount
            citiesCount
            regionsCount
            totalClients
            tariffPriceMin
            tariffPriceMax
          }
        }
      }
    `,
    variables: {},
    context: {
      headers: getCommonHeaders.value,
    },
    fetchPolicy: 'no-cache',
  })

  return res.data.core.globalAgg
}
const fetchLatestConnected = async () => {
  const res = await client.query({
    query: gql`
  query getLatestConnected {
    core {
      globalAgg {
        latestConnectedTariffs {
          cityName
          time
          tariff {
            id
            name
              wireless
            provider {
              id
              name
              logoT {
                url
                unoptimized
              }
            }
            payment {
              price
              promoPeriod
              promoPrice
              complexPrice {
                price
                fromMonth
                promoDisplay
                promoDisplayPercents
                isMainPrice
                isPromoPrice
              }
              promoDisplayPercents
            }
            services {
              mobileSimCount
              tvChannelsCount
              mobileSms
              mobileMinutes
              mobileGigabytes
              internetSpeed
              internetPortSpeed
              internetEffectiveSpeed
              cctvCameraCount
              cctvStorageDays
            wirelessInternetGb
            }
          }
        }
      }
    }
  }
`,
    variables: {},
    context: {
      headers: getCommonHeaders.value,
    },
    fetchPolicy: 'no-cache',
  })

  return res.data.core.globalAgg.latestConnectedTariffs
}

const textAsync = computed(() => data.value?.textAsync)
const articles = computed(() => data.value?.articles)
const tags = computed(() => data.value?.tags)
const allProviders = computed(() => dataAllProviders.value?.providers)
const providers = computed(() => cityProviders.value?.providers?.map(el => el.provider))

let errorStatus
let errorMessage
const [
  { data: cities },
  { data: regions },
  { data, error },
  { data: dataAllProviders },
  { data: latestConnected },
  { data: counts },
  { data: cityProviders },
] = await Promise.all([
  useFetch(`https://${mainStore.domain}/api/locations/address-objects/children/`, {
    key: '100_city_population',
    query: {
      city_type: 'all',
      sort: 'POPULATION',
      level: 'city',
      limit: 100,
      with_available_smart_filters: false,
      with_provider_slugs: false,
      optimized: true,
    },
    headers: {
      ...getCommonHeaders.value,
    },
    getCachedData(key) {
      const data = ctx.payload.data[key] || ctx.static.data[key]
      if (!data) {
        return
      }
      return data
    },
  }),
  useFetch(`https://${mainStore.domain}/api/locations/address-objects/children/`, {
    key: '100_region_population',
    query: {
      city_type: 'all',
      sort: 'POPULATION',
      level: 'region',
      limit: 100,
      with_available_smart_filters: false,
      with_provider_slugs: false,
      optimized: true,
    },
    headers: {
      ...getCommonHeaders.value,
    },
    getCachedData(key) {
      const data = ctx.payload.data[key] || ctx.static.data[key]
      if (!data) {
        return
      }
      return data
    },
  }),
  useAsyncData('mainPageCountry', async () => {
    let textAsync
    let articles
    let tags

    const textAsyncFetch = async () => {
      try {
        textAsync = await getTextAsync({ url: '/' })
      }
      catch (e) {
        errorStatus = e.response.status
        errorMessage = e.message
        throw createError({
          statusCode: e.response.status,
          fatal: true,
          message: e.message,
        })
      }
      finally {
        //
      }
    }

    const articlesFetch = async () => {
      articles = await fetchBlog()
    }
    const tagsFetch = async () => {
      tags = await fetchTags()
    }

    await Promise.all([
      textAsyncFetch(),
      articlesFetch(),
      tagsFetch(),
    ])

    return {
      textAsync,
      articles,
      tags,
    }
  }, {
    transform(input) {
      return {
        ...input,
        fetchedAt: new Date(),
      }
    },
    getCachedData(key) {
      const data = ctx.payload.data[key] || ctx.static.data[key]
      if (!data) {
        return
      }

      const expirationDate = new Date(data.fetchedAt)
      expirationDate.setTime(expirationDate.getTime() + 7 * 60 * 60 * 1000)
      const isExpired = expirationDate.getTime() < Date.now()

      if (isExpired) {
        return
      }
      return data
    },
  }),
  useAsyncData('allProviders' + removeUtm($route.fullPath), async () => {
    const providers = await fetchProviders()
    return {
      providers,
    }
  }, {
    transform(input) {
      return {
        ...input,
        fetchedAt: new Date(),
      }
    },
    getCachedData(key) {
      const data = ctx.payload.data[key] || ctx.static.data[key]
      if (!data) {
        return
      }

      const expirationDate = new Date(data.fetchedAt)
      expirationDate.setTime(expirationDate.getTime() + 7 * 60 * 60 * 1000)
      const isExpired = expirationDate.getTime() < Date.now()

      if (isExpired) {
        return
      }
      return data
    },
  }),
  useAsyncData('latestConnected' + removeUtm($route.fullPath), async () => await fetchLatestConnected()),
  useAsyncData('counts' + removeUtm($route.fullPath), async () => await fetchCounts()),
  useAsyncData('cityProviders' + removeUtm($route.fullPath) + (currentCity.value?.fias_id || ''), async () => {
    let providers
    if (currentCity.value?.fias_id) providers = await fetchProvidersList(
      {
        url: undefined,
        hash: undefined,
      }, {
        tariffless: true,
        offset: 0,
        limit: 12,
      },
    )
    return {
      providers,
    }
  }, {
    transform(input) {
      return {
        ...input,
        fetchedAt: new Date(),
      }
    },
    getCachedData(key) {
      const data = ctx.payload.data[key] || ctx.static.data[key]
      if (!data) {
        return
      }

      const expirationDate = new Date(data.fetchedAt)
      expirationDate.setTime(expirationDate.getTime() + 7 * 60 * 60 * 1000)
      const isExpired = expirationDate.getTime() < Date.now()

      if (isExpired) {
        return
      }
      return data
    },
  }),
])

if (error.value) {
  throw createError({
    statusCode: errorStatus,
    fatal: true,
    message: errorMessage,
  })
}

useHead(() => ({
  title: () =>
    textAsync.value?.title
      ? textAggReplace(
        textAsync.value.title,
        getAddressText.value,
      )
      : '',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: () =>
        textAsync.value?.description
          ? textAggReplace(
            textAsync.value.description,
            getAddressText.value,
          )
          : '',
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: () =>
        textAggReplace(
          h1.value.replace(/-/g, '\u2011').replace(/\\/g, '\n'),
          getAddressText.value,
        ),
    },
    {
      hid: 'og:image',
      property: 'og:image',
      content: 'https://dominternet.ru/android-icon-192x192.png',
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: () =>
        textAggReplace(textAsync.value?.description, getAddressText.value),
    },
    {
      name: () => textAsync.value?.index ? '' : 'robots',
      content: () => textAsync.value?.index ? '' : 'noindex,nofollow',
    },
  ],
}))
</script>

<style scoped lang="scss">
.main-page {
  &:deep(.d-breadcrumbs) {
    display: none;
  }
  .sub-h {
    padding-bottom: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      padding-bottom: 12px;
    }
  }
  .q-block {
    margin-top: 64px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 48px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-top: 40px;
    }
  }
  &:deep(.b-help) {
    margin-top: 64px;
    margin-bottom: 48px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 48px;
      margin-bottom: 40px;
    }
  }
  .b-cities {
    margin-top: 64px;
    margin-bottom: 64px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 48px;
      margin-bottom: 48px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
}
</style>
